/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useContext } from "react";
import PropTypes from 'prop-types';
import BodyClassName from "react-body-classname";
import { useStaticQuery, graphql } from "gatsby";
import { StoreContext } from "../context/StoreContext";
import Header from './Header';
import Footer from './Footer';
import './layout.css';

const Layout = ({ children, baseRoute, homeBottomBlurb, blogCategories }) => {
  const { isCartOpen, toggleCartOpen } = useContext(StoreContext);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);
  // console.group("Layout");
  // console.log(blogCategories);
  // console.groupEnd();
  if (data) {
    return (
      <BodyClassName className={isCartOpen ? "noscroll" : ""}>
        <>
          <div className="dimmer" onClick={() => toggleCartOpen()}></div>
          <Header
            siteTitle={data.site.siteMetadata?.title || `Title`}
            siteDescription={
              data.site.siteMetadata?.description || `Description`
            }
            blogCategories={blogCategories}
          />
          <div className={baseRoute}>
            <main>{children}</main>
            <Footer baseRoute={baseRoute} homeBottomBlurb={homeBottomBlurb} />
          </div>
        </>
      </BodyClassName>
    );
  } else {
    return null;
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  baseRoute: PropTypes.string,
  homeBottomBlurb: PropTypes.string,
};

export default Layout;
