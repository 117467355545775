import React, { useEffect } from 'react';
import Heart from '@images/svg/heart-solid.svg';
import Coffee from '@images/svg/coffee-solid.svg';
import moment from 'moment';
import PinterestIcon from '@images/svg/pinterest-square-brands.svg';
import TwitterIcon from '@images/svg/twitter-square-brands.svg';
import InstagramIcon from '@images/svg/instagram-square-brands.svg';
import FacebookIcon from '@images/svg/facebook-square-brands.svg';
import FooterMenu from '@components/FooterMenu';
import EmailListForm from './EmailListForm';



export default function Footer(props) {
  const { baseRoute, homeBottomBlurb } = props;
  // console.group("Footer");
  // console.log(props);
  // console.groupEnd();

  const socialMediaProfiles = [
    {
      platform: "Instagram",
      url: "https://instagram.com/wilderandweathered",
      img: InstagramIcon,
    },
    {
      platform: "Facebook",
      url: "https://facebook.com/wilderandweathered",
      img: FacebookIcon,

    },
    {
      platform: "Twitter",
      url: "https://twitter.com/wilderweathered",
      img: TwitterIcon,
    },
    {
      platform: "Pinterest",
      url: "https://pinterest.com/wilderandweathered",
      img: PinterestIcon,
    },
  ];

  if(props) {
    return (
      <footer>
        <EmailListForm />
        <section className={`contained ${baseRoute}`}>
          {baseRoute === "home" ? (
            <div
              className="footer-blurb"
              dangerouslySetInnerHTML={{
                __html: homeBottomBlurb,
              }}
            />
          ) : null}
          <div>
            <FooterMenu />
            {socialMediaProfiles && (
              <div id="social-media-links">
                {socialMediaProfiles.map((profile, index) => (
                  <a
                    key={index}
                    href={profile.url}
                    target="_blank"
                    rel="noreferrer"
                    alt={`Follow us on ${profile.platform}`}
                    title={`Follow us on ${profile.platform}`}
                  >
                    {React.createElement(profile.img)}
                  </a>
                ))}
              </div>
            )}
          </div>
        </section>

        <div id="browser">
          It's best to follow our adventures in
          <br />
          <a
            className="gradient"
            href="https://www.google.com/chrome"
            target="_blank"
            rel="noreferrer"
          >
            Chrome
          </a>
          ,{" "}
          <a
            className="gradient"
            href="https://www.mozilla.org/en-US/firefox/new/"
            target="_blank"
            rel="noreferrer"
          >
            Firefox
          </a>
          , or{" "}
          <a
            className="gradient"
            href="https://www.apple.com/safari/"
            target="_blank"
            rel="noreferrer"
          >
            Safari
          </a>
          .
        </div>

        <div className="copyright">
          Wilder &amp; Weathered © 2018-{moment().format("YYYY")}{" "}
        </div>

        <div id="made-with-wrapper">
          <p className="spacing">
            Made with <Heart className="heart" /> &amp;{" "}
            <Coffee className="coffee" /> in Wisconsin
          </p>
          <p>
            Created by{" "}
            <a
              href="https://brightersidestudio.com"
              className="bss-link"
              target="_blank"
              rel="noreferrer"
            >
              Brighter Side Studio LLC
            </a>
          </p>
        </div>
      </footer>
    );
  } else {
    return null;
  }

}

