import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { useToggle } from '@hooks/useToggle';
import MenuBurgerIcon from '@images/svg/bars-light.svg';
import MenuCloseIcon from '@images/svg/times-light.svg';

const HeaderMenu = (props) => {
  const { navType = "mobile", blogCategories } = props;
  const { isToggled, toggle } = useToggle(false);
  const menuList = [
    {
      label: 'Home',
      path: '/',
    },
    {
      label: 'Shop',
      path: '/shop',
    },
    {
      label: 'Blog',
      path: '/blog',
    },
    {
      label: 'FAQ',
      path: '/faq',
    },
  ];

  // console.group('HeaderMenu');
  // console.log(blogCategories);
  // console.groupEnd();

  if (navType === 'mobile') {
    return (
      <nav className="global-nav">
        {/* {blogCategories && <p>{JSON.stringify(blogCategories, null, 2)}</p>} */}
        <div onClick={toggle} id="menu-toggle-button" aria-hidden="true">
          {isToggled ? (
            <MenuCloseIcon className="close-icon" />
          ) : (
            <MenuBurgerIcon className="burger-icon" />
          )}
        </div>
        <ul id="header-mobile-menu" className={isToggled ? "open" : ""}>
          {menuList &&
            menuList.map((el, index) => {
              // console.log(el.label);
              let submenu = null;
              if(el.label === 'Blog' && blogCategories) {
                // console.log("in it", blogCategories);
                submenu = blogCategories.map((category, index) => {
                  return (
                    <li key={index}>
                      <Link to={`/blog/${category.link}`}>{category.name}</Link>
                    </li>
                  );
                });
                // console.group("submenu");
                // console.log(submenu);
                // console.groupEnd();
              }

              return (
                <li key={index}>
                  <Link
                    to={el.path || "/"}
                    activeClassName="active"
                    onClick={toggle}
                  >
                    {el.label}
                  </Link>
                  {/* {el.label === "Blog" && submenu && (
                    <ul dangerouslySetInnerHTML={{ __html: submenu }}></ul>
                  )} */}
                </li>
              );
            })}
        </ul>
      </nav>
    );
  }
  if (navType === 'desktop') {
    return (
      <nav className="global-nav">
        <ul id="header-desktop-menu">
          {menuList &&
            menuList.map((el, index) => (
              <li key={index}>
                <Link to={el.path || '/'} activeClassName="active">
                  {el.label}
                </Link>
              </li>
            ))}
        </ul>
      </nav>
    );
  }
};

export default HeaderMenu;
